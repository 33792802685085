<script>

    export default {
        data() {
            this.searchNoClear = {current: 1, size: 10};
            return {
                table: {
                    loading: false,
                    data: [],
                    total: 0,
                    height: 500
                },
                audit: {
                    visible: false,
                    id: null
                }
            }
        },
        mounted() {
            this.refresh();
        },
        activated() {
            //解决fixed导致表格错位 激活页面重新计算表格布局
            this.$nextTick(() => {
                this.$refs.table.doLayout()
            })
        },
        methods: {
            refresh() { //默认刷新列表方法
                this.table.loading = true;
                return this.refreshService(Object.assign(this.search, this.searchNoClear)).then((response) => {
                    let ret = response.data;
                    this.table.data = ret.records;
                    this.table.total = ret.total || 0;
                    if (this.searchNoClear.current !==1 &&(!ret.records || ret.records=== 0)){
                        this.searchNoClear.current--
                        this.refresh()
                        return
                    }
                    return response;
                }).catch(error => {
                    throw error;
                }).finally(() => this.table.loading = false)
            },
            handleSizeChange(val) { //默认当前页大小变化
                this.searchNoClear.size = val;
                return this.refresh();
            },
            handleCurrentChange(val) { //默认当前页变化
                this.searchNoClear.current = val;
                return this.refresh();
            },
            handleSearch() { //默认查询
                this.searchNoClear.current = 1;
                return this.refresh();
            },
            handleClear() { //默认清空方法
                Object.keys(this.search).forEach((key) => this.search[key] = '')
            },
            handleAudit(id) {
                this.audit.id = id;
                this.audit.visible = true;
            }
        }
    }
</script>
